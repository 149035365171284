<template>
<div>
    <PropList :propType="'used'" />
</div>
</template>

<script>
import PropList from './List.vue'
export default {

    components: {
        PropList
    },

    data: () => ({

    }),

    computed: {

    },

    watch: {

    },
    methods: {

    },
    created() {

    },
};
</script>
